@import "styles/colors.scss";

.invite-complete-wrapper {
  margin: auto 90px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.invite-complete {
  display: flex;
  width: 100%;
  height: 100vh;
  margin-bottom: auto;
  font-family: Roboto, serif;
  background: white;

  &-left {
    flex: 1;

    &-success {
      background: #F0F7F9;
      align-content: center;
      text-align: center;
    }

    &-title {
      font-size: 24px;
      font-weight: bold;
      color: #0C758C;
      margin-bottom: 60px;
      margin-top: 73px;
    }

    &-img {
      max-width: 50%;
    }

    .img {
      width: 60%;
    }
  }

  .notify-auth {
    display: flex;
    gap: 15px;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #8C0C0C;
    padding: 10px 16px;
    margin-bottom: 20px;
    background: #FDF5F5;
    z-index: 10;
  }

  &-right {
    flex: 1;
    background: #F0F7F9;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-success {
      background: none;
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      color: #0C758C;
    }

    .text {
      width: 400px;
      margin: 20px 0 40px;
      font-size: 20px;
      text-align: center;

      &-accent {
        color: #0B404B;
        font-weight: bold;
      }
    }

    .blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(5px);
    }

    .logo {
      position: absolute;
      top: 33px;
      right: 50px;
    }
  }

  &-login-button {
    font-weight: 400;
    font-size: 16px;
    background: #0C5463;
    margin: 20px auto 0;
    width: 100px;
  }
}
